import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/times/:date',
      name: 'Times',
      component: () => import(/* webpackChunkName: "Times" */'./views/Times.vue')
    },
    {
      path: '/login-or-register/:code',
      name: 'AuthGate',
      component: () => import(/* webpackChunkName: "AuthGate" */'./views/AuthGate.vue')
    },
    {
      path: '/login/:code',
      name: 'LoginWithReservation',
      component: () => import(/* webpackChunkName: "Login" */'./views/Login.vue')
    },
    {
      path: '/register/:code',
      name: 'RegisterWithReservation',
      component: () => import(/* webpackChunkName: "Register" */'./views/Register.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "Login" */'./views/Login.vue')
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import(/* webpackChunkName: "Register" */'./views/Register.vue')
    },
    {
      path: '/forgotten-password',
      name: 'ForgottenPassword',
      component: () => import(/* webpackChunkName: "ForgottenPassword" */'./views/ForgottenPassword.vue')
    },
    {
      path: '/reserve_from_booking_app/:code',
      name: 'ReservationFromBookingApp',
      component: () => import(/* webpackChunkName: "ReservationFromBookingApp" */'./views/ReservationFromBookingApp.vue')
    },
    {
      path: '/reserve/:code',
      name: 'ReservationAuthenticated',
      component: () => import(/* webpackChunkName: "ReservationAuthenticated" */'./views/ReservationAuthenticated.vue')
    },
    {
      path: '/reserve_as_guest/:code',
      name: 'ReservationGuest',
      component: () => import(/* webpackChunkName: "ReservationGuest" */'./views/ReservationGuest.vue')
    },
    {
      path: '/call_to_reserve/:code',
      name: 'CallToReserve',
      component: () => import(/* webpackChunkName: "CallToReserve" */'./views/CallToReserve.vue')
    },
    {
      path: '/reserved',
      name: 'Reserved',
      component: () => import(/* webpackChunkName: "Reserved" */'./views/Reserved.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

