/* eslint-disable no-undef */
/* eslint no-console: 0 */
import Rails from '@rails/ujs'
Rails.start()

import Vue from 'vue'
import App from './calendar/app.vue'
import { store } from './calendar/store'
import router from './calendar/router'

import VueI18n from "vue-i18n"

import 'vue-loading-overlay/dist/vue-loading.css'

// Fallback locale

Vue.use(VueI18n)

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar, faCheck, faClock, faPencilAlt, faUser, faTimes, faUserFriends, faMusic, faBolt, faDoorOpen, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faClock, faPencilAlt, faCheck, faCalendar, faTimes, faUser, faUserFriends, faMusic, faBolt, faDoorOpen, faPlus, faMinus )
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { ValidationProvider, ValidationObserver } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

import Buefy from 'buefy'
Vue.use(Buefy)

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://ccfa8cd9488d42a581d6bd903ea623c4@o234530.ingest.sentry.io/5550268",
  integrations: [
    new Integrations.BrowserTracing(),
    new VueIntegration({
      Vue,
      attachProps: true,
      logErrors: true,
      tracing: true,
      tracingOptions: {
        trackComponents: true,
      }
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector("#language_code")
  const language = JSON.parse(node.getAttribute("data"))

  const localeNode = document.querySelector("#locale_messages")
  const localeMessages = JSON.parse(localeNode.getAttribute("data"))

  let messages = {}
  messages[language] = localeMessages

  // VueI18n instance
  const i18n = new VueI18n({
    locale: language,
    fallbackLocale: 'en',
    messages
  })
  window.i18n = i18n

  new Vue({
    store: store,
    router: router,
    i18n: i18n,
    render: h => h(App)
  }).$mount('#app')
})
