const fetch = window.fetch

export default {
  authenticate(params, cb) {
    const path = '/customers/session.json'
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  register(params, cb) {
    const path = '/customers/registration.json'
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  resetPassword(params, cb) {
    const path = '/customers/reset_password.json'
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  me(cb) {
    const path = '/customers/session.json'
    fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  logout(params, cb) {
    const path = '/customers/session.json'
    fetch(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then((resp) => cb(resp))
  }
}
